import { type RefObject } from 'react'
import { useEvent } from '@/lib/hooks/useEvent'
import isClient from '@/lib/hooks/shared/isClient'
import { type CallbackSetter } from '@/lib/types/utility'
import noop from './shared/noop'

/**
 * Accepts an event name then returns a callback setter for a function to be performed when the event triggers.
 */
const useGlobalEvent = <TEvent extends Event>(eventName: keyof WindowEventMap, opts?: AddEventListenerOptions) => {
  if (!isClient) {
    return noop as CallbackSetter<TEvent>
  }

  const target = { current: window } as unknown as RefObject<HTMLElement> // that's a bit of a hack but it works
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useEvent<TEvent>(target, eventName, opts)
}

export default useGlobalEvent
